// Variable overrides
$custom-primary: #3c4b64;
$custom-secondary: #2ecc71;
$custom-success: #0aa05f;
$custom-info: #008ec1;
$custom-warning: #f29811;
$custom-danger: #e74c3c;
$custom-light: #ecf0f1;
$custom-dark: #b3b3b3;

$primary: $custom-primary;
$secondary: $custom-secondary;
$success: $custom-success;
$info: $custom-info;
$warning: $custom-warning;
$danger: $custom-danger;
$light: $custom-light;
$dark: $custom-dark;